import React, { Suspense, useState, useEffect } from 'react';
import {
  Layout,
  Menu,
  Dropdown,
  Avatar,
  Typography,
  theme,
  Drawer,
  Spin,
  Button,
  Switch,
} from 'antd';
import { Link, useLocation } from '@reach/router';
import { useDispatch, connect } from 'react-redux';
import { signOutUser } from 'common/actions/signIn';
import { changeView } from 'common/actions/changeView';
import { changePage } from 'common/actions/changePage';
import logo from 'common/assets/header_logo2.png';
import { Loading } from 'components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import OtpVerification from 'screens/OtpVerification';
import { useAPI } from 'common/hooks/api';
import { CloudOutlined, FireOutlined } from '@ant-design/icons';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import LogoLoading from './Templates/LogoLoading';

const { Header, Content, Sider, Footer } = Layout;
const { Text } = Typography;

const ScreenWrapper = ({
  user,
  routes,
  navigate,
  children,
  moduleConfig,
  companyProfile,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('darkMode')
  );
  const [openKeys, setOpenKeys] = useState([]);
  const currentPath = location.pathname;

  const { data: userData, loading } = useAPI(`/user/meta/`);

  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { isAdmin, isEmployee } = user;

  const onSignOut = () => {
    dispatch(signOutUser());
    navigate('../');
  };
  
  const setCurrentActiveMenu = (pathKey) => {
    const singlePathName = pathKey.split('/').slice(2).join('/');

    function findPath(menu, targetPath) {
      // Iterate through each item in the menu
      for (let item of menu) {
        // Check if the path matches the targetPath
        if (item.path === targetPath) {
          return item;
        }

        // If the item has a subMenu, search recursively in the subMenu
        if (item.isSubMenu && item.subMenu) {
          const foundInSubMenu = findPath(item.subMenu, `/${singlePathName}`);
          if (foundInSubMenu) {
            return foundInSubMenu?.name;
          }
        }
      }

      // Return null if the path is not found
      return null;
    }

    const foundedRoute = routes.find((f) => f.path === `/${singlePathName}`);
  };
  // useEffect(() => {
  //   if (currentPath) {
  //     setCurrentActiveMenu(currentPath);
  //   }
  // }, [currentPath]);

  const onPreferences = () => {
    navigate('/employee/org/perefrences');
    setOpen((prev) => !prev);
  };

  const changeUserView = async (type) => {
    await dispatch(changeView(type));
    window.location.reload('/');
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const lastMode = localStorage.getItem('darkMode');
    if(lastMode != isDarkMode){
      localStorage.setItem('darkMode', isDarkMode);
    }
  }, [isDarkMode]);

  const handleModeToggle = () => {
    setIsDarkMode((prev) => (prev === '0' || prev === null ? '1' : '0'));
    window.location.reload();
  };

  const splitstr = user.name.split('');

  const items = [
    // {
    //   key: '0',
    //   label: 'ViewType',
    //   children: (user.companyType || []).map((type) => ({
    //     key: type,
    //     label: <Text style={{ color: user.viewType === type ? '#1890ff' : '' }}>{type}</Text>,
    //     onClick: () => changeUserView(type),
    //   })),
    // },
    {
      key: '0',
      label: (
        <Link
          style={{ textDecoration: 'none' }}
          to={`/${user.type}/edit-profile/`}
        >
          <Text>Company Profile</Text>
        </Link>
      ),
      ...(isAdmin && {
        label: (
          <Link
            style={{ textDecoration: 'none' }}
            to={`/${user.type}/edit-profile/`}
          >
            <Text>Company Profile</Text>
          </Link>
        ),
      }),
      ...(userData?.vendor_id && {
        label: (
          <Link
            style={{ textDecoration: 'none' }}
            to={`/${user.type}/edit-vendor/${userData?.vendor_id}`}
          >
            <Text>Profile</Text>
          </Link>
        ),
      }),
      onClick: () => {
        setOpen((prev) => !prev);
      },
    },
    {
      key: '1',
      label: <Text>Preferences</Text>,
      onClick: () => onPreferences(),
    },
    {
      key: '2',
      label: <Text type="danger">Log Out</Text>,
      onClick: () => onSignOut(),
    },
  ];
  const handleMenuClick = ({ key }) => {
    if (!key || typeof key !== 'string') return; // ignore if not a string key (like divider)

    const isSubMenu = key.startsWith('sub'); // check if the clicked key is a submenu
    if (isSubMenu) {
      setOpenKeys([key]);
    }
  };

  const handleOpenChange = (keys) => {
    if (keys.length > 1) {
      setOpenKeys([keys.pop()]);
    } else {
      setOpenKeys(keys);
    }
  };

  if (!userData) {
    return <LogoLoading style={{ color: 'black' }} />;
  }

  return userData?.is_verified === false && userData?.is_admin === true ? (
    <OtpVerification user={user} loading={loading} />
  ) : (
    <Spin spinning={loading}>
      <Layout>
        <Sider
          breakpoint="lg"
          // collapsedWidth="0"
          // collapsible
          // collapsedWidth={}
          collapsible={!isMobile}
          collapsedWidth={isMobile ? 0 : collapsedWidth}
          // onCollapse={setCollapsed}
          collapsed={collapsed}
          onCollapse={() => {
            setCollapsed((prev) => !prev);
            setCollapsedWidth(80);
            setIsCollapsed(!isCollapsed);
          }}
          className="scrollbar-hidden"
          style={{
            minHeight: 'calc(100vh - 46px)',
            height: 'calc(100vh - 46px)',
            // overflowY: 'auto',
          }}
        >
          {!collapsed && (
            <div
              style={{
                background: colorBgContainer,
                display: 'flex',
                height: '46px',
                alignItems: 'center',
                paddingLeft: 10,
                justifyContent: 'center',
                position: 'sticky',
              }}
            >
              <img style={{ width: '100%' }} alt="Yantraksh" src={logo} />
            </div>
          )}
          <Menu
            theme={isDarkMode === '1' ? 'light' : 'dark'}
            mode="inline"
            inlineCollapsed
            className="scrollbar-hidden"
            style={{ minHeight: '100%', height: '100%', overflowY: 'auto' }}
            // defaultSelectedKeys="Dashboard"
            defaultSelectedKeys={[currentPath]}
            // selectedKeys={[currentPath]}
            // selectedKeys={['Allotment']}
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            onClick={handleMenuClick}
            items={routes.map((i) => {
              const aliasRouteName = moduleConfig[`${i.key}_alias`];
              if (i.showInSidebar === false) return null;
              if (
                !i.key ||
                (moduleConfig && moduleConfig[i.key]) ||
                (companyProfile && companyProfile[i.key])
              ) {
                return {
                  key: i.name,
                  icon: <FontAwesomeIcon icon={i?.icon} />,
                  label: i.subMenu ? (
                    i.name
                  ) : (
                    <Link
                      to={`/${user.type}${i.path}`}
                      key={i.name}
                      onClick={() => changePage(1)}
                    >
                      {aliasRouteName || i.name}
                    </Link>
                  ),
                  ...(i.subMenu && {
                    children: i.subMenu.map((subItem) => {
                      const aliasSubMenuName =
                        moduleConfig[`${subItem.key}_alias`];

                      if (
                        !subItem.key ||
                        (moduleConfig && moduleConfig[subItem.key]) ||
                        (companyProfile && companyProfile[subItem.key])
                      ) {
                        return {
                          key: subItem.name,
                          label: (
                            <Link
                              to={`/${user.type}${subItem.path}`}
                              key={subItem.name}
                              onClick={() => changePage(1)}
                            >
                              {aliasSubMenuName || subItem.name}
                            </Link>
                          ),
                        };
                      }
                    }),
                  }),
                };
              }
            })}
          />
        </Sider>

        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
              paddingRight: 20,
              height: '46px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {collapsed && (
                <img
                  style={{ height: '35px', marginLeft: 15 }}
                  alt="Yantraksh"
                  src={logo}
                />
              )}
            </div>

            <Drawer
              title={`Hi ${user.name ? user.name : 'user'} ( ${
                user.username
              } ) `}
              placement="right"
              onClose={onClose}
              open={open}
            >
              {isAdmin ? (
                <Menu theme="light" mode="inline">
                  {items.map((item) => (
                    <Menu.Item key={item.key} onClick={item.onClick}>
                      {item.label}
                    </Menu.Item>
                  ))}
                </Menu>
              ) : (
                <Menu theme="light" mode="inline">
                  {items
                    .filter((f) => f.key !== '1')
                    .map((item) => (
                      <Menu.Item key={item.key} onClick={item.onClick}>
                        {item.label}
                      </Menu.Item>
                    ))}
                </Menu>
              )}
            </Drawer>

            {/* <Dropdown menu={{ items }} trigger={['click']}> */}
            <div className="row align-center">
              {/* <Button
                shape="circle"
                icon={isDarkMode === '1' ? <FireOutlined /> : <CloudOutlined />}
                onClick={handleModeToggle}
                style={{ marginRight: '1rem' }}
              /> */}

              <div style={{ marginRight: '1rem' }}>
                <Switch
                  size="small"
                  checked={isDarkMode === '1'}
                  onChange={handleModeToggle}
                  // color="black"
                  style={{
                    background: `${
                      isDarkMode === '1' ? '#3c3b3b' : 'rgb(161 161 161)'
                    }`,
                  }}
                  unCheckedChildren={
                    <FontAwesomeIcon icon={faMoon} color="white" />
                  }
                  checkedChildren={
                    <FontAwesomeIcon icon={faSun} color="orange" />
                  }
                />
              </div>

              <div style={{ marginRight: 10 }}>
                <Typography.Text>{user.username}</Typography.Text>
              </div>

              <Avatar
                onClick={showDrawer}
                style={{
                  textTransform: 'uppercase',
                  backgroundColor: '#5986c2',
                  cursor: 'pointer',
                }}
                size="default"
              >
                <span>{(splitstr[0] || '').slice(0, 1)}</span>
                <span>{(splitstr[1] || '').slice(0, 1)}</span>
              </Avatar>
            </div>
          </Header>

          <Content
            style={{
              margin: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '98%',
            }}
          >
            <Suspense fallback={<LogoLoading />}>
              <div
                style={{
                  padding: 24,
                  background: colorBgContainer,
                  borderRadius: 8,
                  boxShadow: '0px 0px 16px 0px #e2e2e2',
                  maxHeight: 'calc(100vh - 80px)',
                  height: '100vh',
                  overflowY: 'auto',
                  width: '100vw',
                }}
              >
                {children}
              </div>
            </Suspense>
          </Content>

          {/* <Footer style={{ maxHeight: '36px', textAlign: 'right', paddingTop: 0 }}>
          <a target="_blank" rel="noopener noreferrer" href="https://yantraksh.com">
            Trakkia Technology Pvt. Ltd.
          </a>
          &nbsp; &copy; All rights reserved
        </Footer> */}
        </Layout>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user.userMeta };
};

export default connect(mapStateToProps, { changePage })(ScreenWrapper);
