import './App.css';
import 'antd/dist/reset.css';
import '@ant-design/flowchart/dist/index.css';

import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import 'helpers/shared';

import { store, persistor } from 'common/reducers';
import themes from 'constants/theme';
import { Loading } from 'components/Loading';
import RootRouter from 'components/RootRouter';
import { ConfigProvider, theme } from 'antd';
import { useAPI } from 'common/hooks/api';
import { retrieveThemeConfig } from 'common/api/auth';
import { loadAPI } from 'common/helpers/api';

function App() {
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('darkMode')
  );
  const [themeConfigToken, setThemeConfigToken] = useState(null);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<Loading />}>
            <ConfigProvider
              theme={{
                token:
                  localStorage?.getItem('themeConfigData') != undefined
                    ? JSON.parse(localStorage.getItem('themeConfigData') || {})
                    : themeConfigToken,
                algorithm: isDarkMode === '1' ? darkAlgorithm : defaultAlgorithm,
                components: {
                  Badge: {
                    dotSize: 12,
                    statusSize: 12
                  },
                },
              }}
            >
          <RootRouter />
          </ConfigProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
