import Axios from 'axios';
import { loadAPI } from '../helpers/api';

const API_KEY = 'QUhZVWhPR1MzanA4Z09oMWRuOGFkOU40U3ZQRDZIeDB1UGM3THdwaQ==';

export const getJWTTokens = ({ username, password }) =>
  loadAPI(`/api/token/`, {
    method: 'POST',
    data: { username, password },
    secure: false,
  });

// export const isUserVerified = ({ username }) =>
//   loadAPI(`/verification/`, {
//     params: { username },
//     secure: false,
//   });
// export const getBarcodes = ({ id }) => {
//   return loadAPI(`asn-barcodes/?asn=${id}`, {
//     method: 'GET',
//   });
// };
export const getBarcodesImages = (
  id,
  company,
  gridValue,
  categoryItems,
  txnType
) => {
  return loadAPI(
    `download-barcodes-image/?id=${id}&company=${company}&grid=${gridValue}&category=${categoryItems}&txn_type=${txnType}`,
    {
      method: 'GET',
    }
  );
};

export const getItemsgroupsExp = (id) => {
  return loadAPI(`itemsgroups-exp/?id=${id}`, {
    method: 'GET',
  });
};

export const deleteProducts = (id) => {
  return loadAPI(`products/${id}/delete/`, {
    method: 'DELETE',
    secure: true,
  });
};
export const deleteItemGroups = (id) => {
  return loadAPI(`itemsgroups/${id}/delete/`, {
    method: 'DELETE',
    secure: true,
  });
};
export const deleteCustomers = (id) => {
  return loadAPI(`customers/${id}/delete/`, {
    method: 'DELETE',
    secure: true,
  });
};
export const deleteVendors = (id) => {
  return loadAPI(`vendors/${id}/delete/`, {
    method: 'DELETE',
    secure: true,
  });
};
export const deleteFlows = (id) => {
  return loadAPI(`flows/${id}/delete/`, {
    method: 'DELETE',
    secure: true,
  });
};
export const deleteWarehouses = (id) => {
  return loadAPI(`warehouse/${id}/delete/`, {
    method: 'DELETE',
    secure: true,
  });
};

export const verifyUser = ({ username, otp }) =>
  loadAPI('/verifyOTP/', {
    method: 'POST',
    data: { username, otp },
    secure: false,
  });

export const getUserMeta = () => loadAPI(`/user/meta/`);

export const createEmployee = ({
  username,
  email,
  password,
  first_name,
  last_name,
}) =>
  loadAPI('/create-employee/', {
    method: 'POST',
    data: { username, email, password, first_name, last_name },
    secure: false,
  });

export const createClient = ({
  username,
  email,
  password,
  first_name,
  last_name,
}) =>
  loadAPI('/create-client/', {
    method: 'POST',
    data: { username, email, password, first_name, last_name },
    secure: false,
  });

export const createCompany = (data) =>
  loadAPI('/create-company/', {
    method: 'POST',
    data,
    secure: false,
  });
export const createThemeConfig = (data) => {
  return loadAPI(`create-themeConfig/`, {
    method: 'POST',
    data,
  });
};
export const editThemeConfig = (id, data) => {
  return loadAPI(`edit-themeConfig/${id}/`, {
    method: 'PATCH',
    data,
  });
};
export const retrieveThemeConfig = (id) => {
  return loadAPI(`edit-themeConfig/${id}/`, {
    method: 'GET',
    secure: true,
  });
};

export const createPickList = (pickFormData) =>
  loadAPI('/create-picklist/', {
    method: 'POST',
    secure: true,
    data: pickFormData,
  });
export const retrievePickTable = (transaction_no) =>
  loadAPI(`/allotments-products/?id=${transaction_no}`, {
    method: 'GET',
    secure: true,
  });

export const retrieveClients = () =>
  loadAPI('/clients/', {
    method: 'GET',
    secure: true,
  });

export const createProduct = (req) =>
  loadAPI('/create-product/', {
    method: 'POST',
    data: req,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const createItem = (data) =>
  loadAPI('/create-product/', {
    method: 'POST',
    data,
    secure: true,
    // headers: {
    //   'Content-Type': `multipart/form-data  boundary=${Math.random().toString().substr(2)}`,
    // },
  });

export const editItem = (id, data) => {
  return loadAPI(`/edit-product/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
    // headers: {
    //   'Content-Type': `multipart/form-data  boundary=${Math.random().toString().substr(2)}`,
    // },
  });
};

export const createItemGroup = (data) =>
  loadAPI('/create-itemsgroup/', {
    method: 'POST',
    data,
    secure: true,
    // headers: {
    //   'Content-Type': `multipart/form-data  boundary=${Math.random().toString().substr(2)}`,
    // },
  });

export const editItemGroup = (id, data) =>
  loadAPI(`/edit-itemsgroup/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const retrieveItemGroup = (id) =>
  loadAPI(`/edit-itemsgroup/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveQuotations = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  selectedWareKeys,
  selectedCustKeys,
}) =>
  loadAPI(
    `/quotations/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&customer=${selectedCustKeys.join(',')}`
      : ''
    }${selectedWareKeys?.length > 0
      ? `&warehouse=${selectedWareKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrieveQuotation = (id) =>
  loadAPI(`/edit-quotation/${id}`, {
    method: 'GET',
    secure: true,
  });

export const editQuotations = (id) =>
  loadAPI(`/edit-quotation/${id}`, {
    method: 'GET',
    secure: true,
  });
export const retrieveGRNAMC = ({ companyId, viewType, page, pageSize }) =>
  loadAPI(
    `/grnAMCs/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );
export const retrieveRaiseTickets = ({ companyId, viewType, page, pageSize }) =>
  loadAPI(
    `/amcTickets/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );
export const retrieveActivityLists = ({
  companyId,
  viewType,
  page,
  pageSize,
}) =>
  loadAPI(
    `/amcActivities/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );
export const retrieveAssetsMapping = ({
  companyId,
  viewType,
  page,
  pageSize,
}) =>
  loadAPI(
    `/amcMappings/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrieveGRNs = ({
  companyId,
  viewType,
  page,
  pageSize,
  sortBy,
  selectedWareKeys,
  selectedCustKeys,
  tableStatus,
  tableSearch,
  selectedType,
}) =>
  loadAPI(
    `/grns/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&employee_assigned=${selectedCustKeys.join(',')}`
      : ''
    }${selectedWareKeys?.length > 0
      ? `&warehouse=${selectedWareKeys.join(',')}`
      : ''
    }${selectedType ? `&transaction_type=${selectedType}` : ''}${tableSearch ? `&s=${tableSearch}` : ''
    }`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const createGRNform = (req) =>
  loadAPI('upload-grnserial/', {
    method: 'POST',
    data: req,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const createPutaway = (putawayFormData) =>
  loadAPI('/create-putaway/', {
    method: 'POST',
    secure: true,
    data: putawayFormData,
  });

export const createGrnAmc = (data) =>
  loadAPI('/create-grnAMC/', {
    method: 'POST',
    secure: true,
    data,
  });
export const retrieveGrnAmc = (id) => {
  return loadAPI(`/edit-grnAMC/${id}`, {
    method: 'GET',
    secure: true,
  });
};
export const editGrnAmc = (id, data) => {
  return loadAPI(`/edit-grnAMC/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
};

export const retrieveActivity = (id) => {
  return loadAPI(`/edit-amcActivities/${id}`, {
    method: 'GET',
    secure: true,
  });
};
export const editActivity = (id, data) => {
  return loadAPI(`/edit-amcActivities/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
};
export const createRaiseTicket = (data) =>
  loadAPI('/create-amcTicket/', {
    method: 'POST',
    secure: true,
    data,
  });
export const retrieveRaiseTicket = (id) => {
  return loadAPI(`/edit-amcTicket/${id}`, {
    method: 'GET',
    secure: true,
  });
};
export const editRaiseTicket = (id, data) => {
  return loadAPI(`/edit-amcTicket/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
};

export const retrieveAssetMapping = (id) => {
  return loadAPI(`/edit-amcMapping/${id}`, {
    method: 'GET',
    secure: true,
  });
};
export const editAssetMapping = (id, data) => {
  return loadAPI(`/edit-amcMapping/${id}`, {
    method: 'PATCH',
    secure: true,
    data,
  });
};
export const createMaintenance = (data) =>
  loadAPI('/create-maintenance/', {
    method: 'POST',
    secure: true,
    data,
  });

export const retrieveMaintenance = (id) => {
  return loadAPI(`/edit-maintenance/${id}`, {
    method: 'GET',
    secure: true,
  });
};

export const editMaintenance = (id, data) => {
  return loadAPI(`/edit-maintenance/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
};

export const retrievePutawayy = (id) =>
  loadAPI(`/edit-putaway/${id}/`, {
    method: 'GET',
    secure: true,
  });
export const editPutaway = (id, data) =>
  loadAPI(`/edit-putaway/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const retrievePutaway = ({ companyId, viewType, page, pageSize }) =>
  loadAPI(
    `/putaway-list/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}&status=${'pending'}`,
    {
      method: 'GET',
      secure: true,
    }
  );

// export const retrievePickList = () => {
//   return loadAPI(`/picklist-list/`, { method: 'GET', secure: true });
// };
export const retrievePickList = ({ companyId, viewType, page, pageSize }) =>
  loadAPI(
    `/picklist-list/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrieveMaintenances = ({
  companyId,
  viewType,
  page,
  pageSize,
}) => {
  return loadAPI(
    `/maintenances/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );
};

export const retrieveProducts = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  selectedCustKeys,
}) => {
  return loadAPI(
    `/products/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&category=${selectedCustKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );
};

export const retrieveProductGroups = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  selectedCustKeys,
}) =>
  loadAPI(
    `/itemsgroups/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&category=${selectedCustKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrieveScannedData = ({ page, pageSize }) =>
  loadAPI(`/rfid-dump/?page=${page}&page_size=${pageSize}`, {
    method: 'GET',
    secure: true,
  });

export const retrieveScannedDataWithoutPagination = () =>
  loadAPI(`/rfid-dump-wp/`, {
    method: 'GET',
    secure: true,
  });

export const editProduct = (id, req) => {
  return loadAPI(`/edit-product/${id}/`, {
    method: 'PATCH',
    secure: true,
    data: req,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });
};

// export const editGRN = (id, req) => {
//   return loadAPI(`/edit-product/${id}/`, {
//     method: 'PATCH',
//     secure: true,
//     data: req,
//     headers: {
//       'Content-Type': `multipart/form-data  boundary=${Math.random().toString().substr(2)}`,
//     },
//   });
// };

export const createBarcodePref = (data) =>
  loadAPI('create-barcodeprefrences/', {
    method: 'POST',
    secure: true,
    data,
  });

export const retrieveBarcodePref = (id, data) =>
  loadAPI(`/edit-barcodeprefrences/${id}/`, {
    method: 'GET',
    secure: true,
    data,
  });
export const editBarcodePref = (id, data) =>
  loadAPI(`/edit-barcodeprefrences/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const createDepartments = (data) =>
  loadAPI('create-department/', {
    method: 'POST',
    secure: true,
    data,
  });
export const retrieveDepartment = (id) =>
  loadAPI(`/edit-department/${id}/`, {
    method: 'GET',
    secure: true,
  });
export const editDepartment = (id, data) =>
  loadAPI(`/edit-department/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
export const createRoles = (data) =>
  loadAPI('create-role/', {
    method: 'POST',
    secure: true,
    data,
  });
export const retrieveRole = (id) =>
  loadAPI(`/edit-role/${id}/`, {
    method: 'GET',
    secure: true,
  });
export const editRole = (id, data) =>
  loadAPI(`/edit-role/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const createPageSizePrf = (data) =>
  loadAPI('create-page-settings/', {
    method: 'POST',
    secure: true,
    data,
  });

export const retrievePageSizePrf = (id) =>
  loadAPI(`/edit-page-settings/${id}/`, {
    method: 'GET',
    secure: true,
  });
export const editPageSizePrf = (id, data) =>
  loadAPI(`/edit-page-settings/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const createTransactionPref = (data) =>
  loadAPI('create-txnprefrences/', {
    method: 'POST',
    secure: true,
    data,
  });

export const editTransactionPref = (id, data) =>
  loadAPI(`/edit-txnprefrences/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const retrieveTransactionPref = (id, data) =>
  loadAPI(`/edit-txnprefrences/${id}/`, {
    method: 'GET',
    secure: true,
    data,
  });

export const retrieveitem = (id) => {
  return loadAPI(`/edit-product/${id}/`, {
    method: 'GET',
    secure: true,
  });
};

export const retrieveProduct = (id) => {
  return loadAPI(`/edit-product/${id}`, {
    method: 'GET',
    secure: true,
  });
};

export const createPO = (data) =>
  loadAPI('/create-po/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createASNforPO = (data) =>
  loadAPI('/create-asn/', {
    method: 'POST',
    secure: true,
    data,
  });

export const retrievePO = (id) =>
  loadAPI(`/edit-po/${id}/`, {
    method: 'GET',
    secure: true,
  });
export const retrieveInvoice = (id) =>
  loadAPI(`/sales-invoices/${id}`, {
    method: 'GET',
    secure: true,
  });
export const retrieveASN = (id) =>
  loadAPI(`/edit-asn/${id}/`, {
    method: 'GET',
    secure: true,
  });
export const editASN = (id, data) =>
  loadAPI(`/edit-asn/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const editPO = (id, data) =>
  loadAPI(`edit-po/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const deleteProduct = (id) =>
  loadAPI(`/edit-product/${id}`, {
    method: 'DELETE',
    secure: true,
  });

export const createKit = (data) => {
  return loadAPI('/create-kit/', {
    method: 'POST',
    secure: true,
    data,
  });
};

export const createInvoice = (data) => {
  return loadAPI('/create-invoice/', {
    method: 'POST',
    secure: true,
    data,
  });
};

export const createCustomer = (data) =>
  loadAPI('/create-customer/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createVendor = (data) =>
  loadAPI('/create-vendor/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createAccess = (data) =>
  loadAPI('/create-access/', {
    method: 'POST',
    secure: true,
    data,
  });

export const editAccess = (id, data) =>
  loadAPI(`edit-access/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
export const retrieveAccess = (id) =>
  loadAPI(`/edit-access/${id}/`, {
    method: 'GET',
    secure: true,
    // data,
  });

export const getAccessId = (type) =>
  loadAPI(`/get-accessID/?emp_type=${type}`, {
    method: 'GET',
    secure: true,
    // data,
  });
export const editVendor = (id, data) =>
  loadAPI(`edit-vendor/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const retriveVendors = (id) =>
  loadAPI(`/edit-vendor/${id}/`, {
    method: 'GET',
    secure: true,
    // data,
  });

export const createSalesOrder = (data) =>
  loadAPI('create-salesorder/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createDeliveryChallanCustomer = (data) =>
  loadAPI('create-customerdc/', {
    method: 'POST',
    secure: true,
    data,
  });
export const retrieveDeliveryChallanCustomer = (id) =>
  loadAPI(`edit-customerdc/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const createDeliveryChallanRelocation = (data) =>
  loadAPI('create-customerdc/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createDeliveryChallanAllotment = (data) =>
  loadAPI('create-allotment/', {
    method: 'POST',
    secure: true,
    data,
  });
export const createDeliveryOutWard = (data) =>
  loadAPI('create-allotment/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createDeliveryChallanReturn = (data) =>
  loadAPI('create-return/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createDeliveryChallanOutward = (data) =>
  loadAPI('create-customerdc/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createOutward = (data) =>
  loadAPI('create-outward/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createEmptyStock = (data) =>
  loadAPI(`create-empty-stocks/`, {
    method: 'POST',
    secure: true,
    data,
  });

export const createDeliveryChallanVendor = (data) =>
  loadAPI('create-vendordc/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createDeliveryChallanWarehouse = (data) =>
  loadAPI('create-warehousedc/', {
    method: 'POST',
    secure: true,
    data,
  });
export const retrieveWarehouseDc = (id) =>
  loadAPI(`/edit-warehousedc/${id}/`, {
    method: 'GET',
    secure: true,
  });
export const editWarehouseDc = (id, data) =>
  loadAPI(`/edit-warehousedc/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
export const retrieveVendorDc = (id) =>
  loadAPI(`/edit-vendordc/${id}/`, {
    method: 'GET',
    secure: true,
  });
export const editVendorDc = (id, data) =>
  loadAPI(`/edit-vendordc/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

// export const editSalesOrder = (
//     id,
//     {
//         sales_order_date,
//         expected_shipment_date,
//         items,
//         document,
//         branch,
//         customer,
//         amount,
//         sales_order_number,
//         reference_number,
//         payment_terms,
//         customer_notes,
//         terms_conditions,
//         shipping_charges,
//         adjustment,
//     },) => loadAPI(`edit-salesorder/${id}/`, {
//     method: 'PATCH',
//     data: {
//         sales_order_date,
//         expected_shipment_date,
//         items,
//         document,
//         branch,
//         customer,
//         amount,
//         sales_order_number,
//         reference_number,
//         payment_terms,
//         customer_notes,
//         terms_conditions,
//         shipping_charges,
//         adjustment,
//     },
//     secure: true,
// });

export const retriveSalesOrder = (id) => {
  console.log(id, 'edit so id');
  return loadAPI(`/edit-salesorder/${id}/`, {
    method: 'GET',
    secure: true,
  });
};

export const editSalesOrder = (id, req) => {
  return loadAPI(`/edit-salesorder/${id}/`, {
    method: 'PATCH',
    secure: true,
    data: req,
    // headers: {
    //   'Content-Type': `multipart/form-data  boundary=${Math.random().toString().substr(2)}`,
    // },
  });
};

export const editReturnChallan = (id, req) => {
  return loadAPI(`/edit-return/${id}/`, {
    method: 'PATCH',
    secure: true,
    data: req,
    // headers: {
    //   'Content-Type': `multipart/form-data  boundary=${Math.random().toString().substr(2)}`,
    // },
  });
};

export const getRateTax = (prod, pType) => {
  return loadAPI(`/rate-tax/?pType=${pType}&product=${prod}`, {
    method: 'GET',
    secure: true,
  });
};

export const createQuotation = (data) =>
  loadAPI('/create-quotation/', {
    method: 'POST',
    secure: true,
    data,
  });

export const editQuotation = (id, req) =>
  loadAPI(`/edit-quotation/${id}/`, {
    method: 'PATCH',
    secure: true,
    data: req,
  });
export const editInvoice = (id, data) =>
  loadAPI(`/sales-invoices/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const editKit = (
  id,
  {
    kit_name,
    kit_info,
    components_per_kit,
    kit_client,
    part_name,
    part_number,
    kit_type,
    products,
    active,
  }
) =>
  loadAPI(`/edit-kit/${id}/`, {
    method: 'PATCH',
    secure: true,
    data: {
      kit_name,
      kit_info,
      components_per_kit,
      kit_client,
      part_name,
      part_number,
      kit_type,
      products,
      active,
    },
  });

export const retrieveKits = ({ companyId, viewType, page, pageSize }) =>
  loadAPI(
    `/kits/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrieveKitsClients = () =>
  loadAPI('/client-kits/', {
    method: 'GET',
    secure: true,
  });

export const retrieveKit = (id) =>
  loadAPI(`/edit-kit/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const deleteKit = (id) =>
  loadAPI(`/edit-kit/${id}`, {
    method: 'DELETE',
    secure: true,
  });

export const createOutward1 = (data) =>
  loadAPI('create-outward/', {
    method: 'POST',
    secure: true,
    data,
  });

export const editOutward = (id, data) =>
  loadAPI(`edit-outward/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
export const editEmptyStock = (id, data) =>
  loadAPI(`edit-empty-stocks/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
export const deleteOutward = (id) =>
  loadAPI(`edit-outward/${id}/`, {
    method: 'Delete',
    secure: true,
  });

export const retrieveOutward = (id) =>
  loadAPI(`edit-outward/${id}/`, {
    method: 'GET',
    secure: true,
  });
export const retrieveOutwardDocket = ({
  companyId,
  viewType,
  page,
  pageSize,
}) =>
  loadAPI(
    `outwards/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrieveAllotmentDocket = ({
  companyId,
  viewType,
  page,
  pageSize,
}) =>
  loadAPI(
    `/allotments-table/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrieveOutwardDocketEmp = (id) =>
  loadAPI(`outwards-print/?id=${id}`, {
    method: 'GET',
    secure: true,
  });

// export const createVendor = ({
//   name,
//   street,
//   city,
//   pincode,
//   state,
//   contact_person_name,
//   contact_person_no,
//   email,
//   type,
//   payment_terms,
//   pan,
//   gst,
//   code,
//   remarks,
//   beneficiary_name,
//   account_no,
//   bank_name,
//   ifsc,
//   active,
// }) =>
//   loadAPI('/create-vendor/', {
//     method: 'POST',
//     data: {
//       name,
//       street,
//       city,
//       pincode,
//       state,
//       contact_person_name,
//       contact_person_no,
//       email,
//       type,
//       payment_terms,
//       pan,
//       gst,
//       code,
//       remarks,
//       beneficiary_name,
//       account_no,
//       bank_name,
//       ifsc,
//       active,
//     },
//     secure: true,
//   });

// export const editVendor = (
//   id,
//   {
//     name,
//     street,
//     city,
//     pincode,
//     state,
//     contact_person_name,
//     contact_person_no,
//     email,
//     type,
//     payment_terms,
//     pan,
//     gst,
//     code,
//     remarks,
//     beneficiary_name,
//     account_no,
//     bank_name,
//     ifsc,
//     active,
//   },
// ) =>
//   loadAPI(`/edit-vendor/${id}/`, {
//     method: 'PATCH',
//     data: {
//       name,
//       street,
//       city,
//       pincode,
//       state,
//       contact_person_name,
//       contact_person_no,
//       email,
//       type,
//       payment_terms,
//       pan,
//       gst,
//       code,
//       remarks,
//       beneficiary_name,
//       account_no,
//       bank_name,
//       ifsc,
//       active,
//     },
//     secure: true,
//   });

export const deleteVendor = (id) =>
  loadAPI(`/edit-vendor/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const retrieveVendor = (id) =>
  loadAPI(`/edit-vendor/${id},/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveVendors = ({
  companyId,
  viewType,
  page,
  pageSize,
  sortBy,
  tableSearch,
  tableStatus,
  selectedState,
  selectedType,
}) =>
  loadAPI(
    `/vendors/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedType ? `&vendor_type=${selectedType}` : ''}${selectedState ? `&state=${selectedState}` : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const createWarehouse = (req) =>
  loadAPI('/create-warehouse/', {
    method: 'POST',
    secure: true,
    data: req,
  });

export const editWarehouse = (id, req) =>
  loadAPI(`/edit-warehouse/${id}/`, {
    method: 'PATCH',
    secure: true,
    data: req,
  });

export const deleteWarehouse = (id) =>
  loadAPI(`/edit-warehouse/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const retrieveWarehouse = (id) =>
  loadAPI(`/edit-warehouse/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveWarehouses = ({
  companyId,
  viewType,
  page,
  pageSize,
  sortBy,
  tableSearch,
  tableStatus,
  selectedState,
}) =>
  loadAPI(
    `/warehouse/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedState ? `&state=${selectedState}` : ''}${tableSearch ? `&s=${tableSearch}` : ''
    }`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const editClientProfile = (id, req) =>
  loadAPI(`/client-profile/${id}/`, {
    method: 'PATCH',
    secure: true,
    data: req,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const retrieveClientProfile = (id) =>
  loadAPI(`/client-profile/${id}`, {
    method: 'GET',
    secure: true,
  });

export const editEmployeeProfile = (id, req) =>
  loadAPI(`/emp-profile/${id}/`, {
    method: 'PATCH',
    secure: true,
    data: req,
  });

export const editCompanyProfile = (id, req) =>
  loadAPI(`/company-profile/${id}/`, {
    method: 'PATCH',
    secure: true,
    data: req,
  });
export const manageCompanyProfile = (id, req) =>
  loadAPI(`/emp-profile/${id}/`, {
    method: 'PATCH',
    secure: true,
    data: req,
  });

export const retrieveEmployeeProfile = (id) =>
  loadAPI(`/company-profile/${id}`, {
    method: 'GET',
    secure: true,
  });

export const createReceiverClient = ({
  name,
  city,
  address,
  emitter,
  pan,
  gst,
  active,
}) =>
  loadAPI('/create-receiverclient/', {
    method: 'POST',
    data: { name, city, address, emitter, pan, gst, active },
    secure: true,
  });

export const editReceiverClient = (
  id,
  { name, city, address, emitter, pan, gst, active }
) =>
  loadAPI(`/edit-receiverclient/${id}/`, {
    method: 'PATCH',
    data: { name, city, address, emitter, pan, gst, active },
    secure: true,
  });

export const deleteReceiverClient = (id) =>
  loadAPI(`/edit-receiverclient/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const retrieveReceiverClient = (id) =>
  loadAPI(`/edit-receiverclient/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retieveReceiverClients = (id) =>
  loadAPI(`/receiverclients/`, {
    method: 'GET',
    secure: true,
  });

export const getItemDataWithItemGroupIds = (data) =>
  loadAPI(`/itemsgroups-batch-exp/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const retieveReceiverClientsClientSide = () =>
  loadAPI(`/client-reciever-client/`, {
    method: 'GET',
    secure: true,
  });

export const createFlow = (req) =>
  loadAPI('/create-flow/', {
    method: 'POST',
    secure: true,
    data: req,
  });

// export const editFlow = (
//   id,
//   { flow_name, flow_info, flow_type, flow_days, sender_client, receiver_client, kits, active },
// ) =>
//   loadAPI(`/edit-flow/${id}/`, {
//     method: 'PATCH',
//     secure: true,
//     data: {
//       flow_name,
//       flow_info,
//       flow_type,
//       flow_days,
//       sender_client,
//       receiver_client,
//       kits,
//       active,
//     },
//   });

export const retreiveCustomers = ({
  companyId,
  viewType,
  page,
  pageSize,
  sortBy,
  tableSearch,
  tableStatus,
  selectedState,
}) =>
  loadAPI(
    `/customers/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedState ? `&state=${selectedState}` : ''}${tableSearch ? `&s=${tableSearch}` : ''
    }`,
    {
      method: 'GET',
      secure: true,
    }
  );
export const retreiveCustomer = (customerId) =>
  loadAPI(`/edit-customer/${customerId}/`, {
    method: 'GET',
    secure: true,
  });

export const editCustomer = (customerId, req) =>
  loadAPI(`/edit-customer/${customerId}/`, {
    method: 'PATCH',
    secure: true,
    data: req,
  });

export const retreiveInvoices = ({ companyId, viewType, page, pageSize }) =>
  loadAPI(
    `/sales-invoices/?company=${companyId}&view=${viewType}&page=${page}&pageSize=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retreiveFlowById = (id) =>
  loadAPI(`/edit-flow/${id}/`, {
    method: 'GET',
    secure: true,
  });
export const retreiveAllotmentById = (id) =>
  loadAPI(`/edit-allotment/${id}/`, {
    method: 'GET',
    secure: true,
  });

// export const retreiveFlow = () =>
//   loadAPI(`/flows/`, {
//     method: 'GET',
//     secure: true,
//   });

export const retreiveFlows = ({
  companyId,
  viewType,
  page,
  pageSize,
  sortBy,
  selectedWareKeys,
  selectedCustKeys,
  tableStatus,
  tableSearch,
  selectedType,
}) =>
  loadAPI(
    `/flows/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&origin=${selectedCustKeys.join(',')}`
      : ''
    }${selectedWareKeys?.length > 0
      ? `&destination=${selectedWareKeys.join(',')}`
      : ''
    }${selectedType ? `&flow_type=${selectedType}` : ''}${tableSearch ? `&s=${tableSearch}` : ''
    }`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retreiveSalesOrders = ({ companyId, viewType, page, pageSize }) =>
  loadAPI(
    `/salesorders/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrieveSalesOrders = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  selectedWareKeys,
  selectedCustKeys,
}) =>
  loadAPI(
    `/salesorders/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&customer=${selectedCustKeys.join(',')}`
      : ''
    }${selectedWareKeys?.length > 0
      ? `&warehouse=${selectedWareKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retreiveDeliveryChallanCustomer = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  selectedWareKeys,
  selectedCustKeys,
}) =>
  loadAPI(
    `/customerdc/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&customer=${selectedCustKeys.join(',')}`
      : ''
    }${selectedWareKeys?.length > 0
      ? `&warehouse=${selectedWareKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retreiveDeliveryChallanFormData = (id) =>
  loadAPI(`edit-customerdc/${id}`, {
    method: 'GET',
    secure: true,
  });

export const editDeliveryChallanFormData = (id, payload) =>
  loadAPI(`edit-customerdc/${id}/`, {
    method: 'PATCH',
    secure: true,
    data: payload,
  });

export const retreiveDeliveryChallanRelocation = ({
  companyId,
  viewType,
  page,
  pageSize,
}) =>
  loadAPI(
    `/customerdc/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retreiveDcWarehouse = ({ companyId, viewType, page, pageSize }) =>
  loadAPI(
    `/warehousedc/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retreiveRelocation = ({
  companyId,
  viewType,
  page,
  pageSize,
  sortBy,
  selectedWareKeys,
  selectedCustKeys,
  tableStatus,
  tableSearch,
}) =>
  loadAPI(
    `/relocations/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&branch_from=${selectedCustKeys.join(',')}`
      : ''
    }${selectedWareKeys?.length > 0
      ? `&branch_to=${selectedWareKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retreiveDeliveryChallanAllotment = ({
  companyId,
  viewType,
  page,
  pageSize,
  selectedCustKeys,
  tableStatus,
  sortBy,
  selectedWareKeys,
  tableSearch,
}) => {
  return loadAPI(
    `/allotments-table/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&customer=${selectedCustKeys.join(',')}`
      : ''
    }${selectedWareKeys?.length > 0
      ? `&warehouse=${selectedWareKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );
};
export const retreiveDCustomerAllotment = ({
  companyId,
  viewType,
  page,
  pageSize,
}) =>
  loadAPI(
    `/customer-allotments-table/?view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retreiveDeliveryChallanReturn = ({
  companyId,
  viewType,
  page,
  pageSize,
  sortBy,
  selectedWareKeys,
  selectedCustKeys,
  tableStatus,
  tableSearch,
  selectedType,
}) =>
  loadAPI(
    `/return-table/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&customer=${selectedCustKeys.join(',')}`
      : ''
    }${selectedType?.length > 0 ? `&vendor=${selectedType.join(',')}` : ''}${selectedWareKeys?.length > 0
      ? `&warehouse=${selectedWareKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retreiveDeliveryChallanOutward = ({
  companyId,
  viewType,
  page,
  pageSize,
}) =>
  loadAPI(
    `/customerdc/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retreiveDeliveryEmptyStock = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  selectedCustKeys,
}) =>
  loadAPI(
    `/empty-stocks/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&customer=${selectedCustKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retreiveOutward = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  selectedCustKeys,
  selectedEmitterKeys,
}) => {
  const params = [
    `company=${companyId}`,
    sortBy ? `sort_by=${sortBy}` : '',
    `view=${viewType}`,
    `page=${page}`,
    `page_size=${pageSize}`,
    tableStatus ? `status=${tableStatus}` : '',
    selectedEmitterKeys?.length > 0
      ? `emitter=${selectedEmitterKeys.join(',')}`
      : '',
    selectedCustKeys?.length > 0
      ? `customer=${selectedCustKeys.join(',')}`
      : '',
    tableSearch ? `s=${tableSearch}` : '',
  ]
    .filter(Boolean)
    .join('&');

  const url = `/outwards/?${params}`;
  return loadAPI(url, {
    method: 'GET',
    secure: true,
  });
};
// loadAPI(
//   `/outwards/?company=${companyId}${
//     sortBy ? `&sort_by=${sortBy}` : ''
//   }&view=${viewType}&page=${page}&page_size=${pageSize}${
//     tableStatus ? `&status=${tableStatus}` : ''
//   }${
//     selectedEmitterKeys?.length > 0
//       ? `&emitter=${selectedEmitterKeys.join(',')}`
//       : ''
//   }  ${
//     selectedCustKeys?.length > 0
//       ? `&customer=${selectedCustKeys.join(',')}`
//       : ''
//   }  ${tableSearch ? `&s=${tableSearch}` : ''}`,
//   {
//     method: 'GET',
//     secure: true,
//   }
// );

export const retreiveDeliveryChallanVendor = ({
  companyId,
  viewType,
  page,
  pageSize,
  sortBy,
  selectedWareKeys,
  selectedCustKeys,
  tableStatus,
  tableSearch,
}) =>
  loadAPI(
    `/vendordc/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&warehouse=${selectedCustKeys.join(',')}`
      : ''
    }${selectedWareKeys?.length > 0
      ? `&vendor=${selectedWareKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retreiveDeliveryChallanWarehouse = ({
  companyId,
  viewType,
  page,
  pageSize,
}) =>
  loadAPI(
    `/warehousedc/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrieveSalesOrder = (id) =>
  loadAPI(`/edit-salesorder/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retreiveFlowsClient = () =>
  loadAPI(`/client-flows/`, {
    method: 'GET',
    secure: true,
  });

export const retreiveMyFlows = () =>
  loadAPI('/myflows/', {
    method: 'GET',
    secure: true,
  });

export const deleteFlow = (id) =>
  loadAPI(`/edit-flow/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const createMr = (data) =>
  loadAPI('/create-mrequest/', {
    method: 'POST',
    data,
    secure: true,
  });

export const editMr = (id, { delivery_required_on, flows }) =>
  loadAPI(`/edit-mrequest/${id}/`, {
    method: 'PATCH',
    data: { delivery_required_on, flows },
    secure: true,
  });

export const editAddMr = (
  id,
  { delivery_required_on, flows, client_id, raised_for }
) =>
  loadAPI(`/mr-edit/${id}/`, {
    method: 'PATCH',
    data: { delivery_required_on, flows, client_id, raised_for },
    secure: true,
  });

export const retrieveMr = (id) =>
  loadAPI(`/edit-mrequest/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveAddMr = (id) =>
  loadAPI(`/mr-edit/${id}/`, {
    method: 'GET',
    secure: true,
  });

// export const retrieveMrs = () =>
//   loadAPI('/mrequests/', {
//     method: 'GET',
//     secure: true,
//   });

export const retrieveMrs = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  selectedCustKeys,
}) => {
  return loadAPI(
    `/mrequests/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&raised_by=${selectedCustKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    { method: 'GET', secure: true }
  );
};

export const deleteMr = (id) =>
  loadAPI(`/edit-mrequest/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const deleteAddMr = (id) =>
  loadAPI(`/admin-mredit/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const createDm = ({ delivery_month, demand_flows, client_id }) =>
  loadAPI('/create-demand/', {
    method: 'POST',
    data: { delivery_month, demand_flows, client_id },
    secure: true,
  });

export const editDm = (id, { delivery_month, demand_flows }) =>
  loadAPI(`/edit-demand/${id}/`, {
    method: 'PATCH',
    data: { delivery_month, demand_flows },
    secure: true,
  });

export const editAddDm = (id, { delivery_required_on, flows, client_id }) =>
  loadAPI(`/admin-mredit/${id}/`, {
    method: 'PATCH',
    data: { delivery_required_on, flows, client_id },
    secure: true,
  });

export const retrieveDm = (id) =>
  loadAPI(`/edit-demand/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveAddDm = (id) =>
  loadAPI(`/admin-mredit/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveDms = () =>
  loadAPI('/demands/', {
    method: 'GET',
    secure: true,
  });

export const retrieveDmsClient = () =>
  loadAPI('/client-demands/', {
    method: 'GET',
    secure: true,
  });

export const deleteDm = (id) =>
  loadAPI(`/edit-demand/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const deleteAddDm = (id) =>
  loadAPI(`/admin-mredit/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const retrieveEmployeeMrs = () =>
  loadAPI('/allmrequest/', {
    method: 'GET',
    secure: true,
  });

export const retrieveEmployeeMrsEfficient = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  selectedCustKeys,
}) =>
  loadAPI(
    `/mrequest-table/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&raised_by=${selectedCustKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const createAllotment = ({
  transaction_no,
  dispatch_date,
  send_from_warehouse,
  sales_order,
  flows,
  is_delivered,
  model,
  driver_name,
  driver_number,
  lr_number,
  vehicle_number,
  freight_charges,
  vehicle_type,
  transport_by,
  expected_delivery,
  remarks,
}) =>
  loadAPI('/create-allotment/', {
    method: 'POST',
    data: {
      transaction_no,
      dispatch_date,
      send_from_warehouse,
      sales_order,
      flows,
      is_delivered,
      model,
      driver_name,
      driver_number,
      lr_number,
      vehicle_number,
      freight_charges,
      vehicle_type,
      transport_by,
      expected_delivery,
      remarks,
    },
    secure: 'true',
  });

// export const editAllotment = (
//   id,
//   {
//     dispatch_date,
//     send_from_warehouse,
//     sales_order,
//     flows,
//     model,
//     driver_name,
//     driver_number,
//     lr_number,
//     vehicle_number,
//     freight_charges,
//     vehicle_type,
//     transport_by,
//     expected_delivery,
//     remarks,
//   },
// ) =>
//   loadAPI(`edit-allotment-patch/${id}/`, {
//     method: 'PATCH',
//     data: {
//       dispatch_date,
//       send_from_warehouse,
//       sales_order,
//       flows,
//       model,
//       driver_name,
//       driver_number,
//       lr_number,
//       vehicle_number,
//       freight_charges,
//       vehicle_type,
//       transport_by,
//       expected_delivery,
//       remarks,
//     },
//     secure: true,
//   });

export const retrieveAllotment = (id) =>
  loadAPI(`/edit-allotment/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveAllotmentbyid = (id) =>
  loadAPI(`/edit-allotment/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveOutwardbyid = (id) =>
  loadAPI(`/edit-outward/${id}/`, {
    methood: 'GET',
    secure: true,
  });

export const retrieveEmptyStockbyid = (id) =>
  loadAPI(`/edit-empty-stocks/${id}/`, {
    methood: 'GET',
    secure: true,
  });

export const retrieveAllotments = (id) =>
  loadAPI('/allotments/', {
    method: 'GET',
    secure: true,
    params: { id },
  });

export const retrieveAllotmentsDockets = (id) =>
  loadAPI(`/allotments-print/?id=${id}`, {
    method: 'GET',
    secure: false,
  });

export const retrieveAllotmentsCalender = (id) =>
  loadAPI(`/allotments-cal/?tno=${id}`, {
    method: 'GET',
    secure: true,
  });

export const deleteAllotment = (id) =>
  loadAPI(`/edit-allotment/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const createGRN = (data) =>
  loadAPI('/create-grn/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createASN = (data) =>
  loadAPI('/create-asn/', {
    method: 'POST',
    secure: true,
    data,
    // headers: {
    //   'Content-Type': 'multipart/form-data  boundary=' + Math.random().toString().substr(2),
    // },
  });

export const editGRN = (id, data) =>
  loadAPI(`/edit-grn/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const retrieveGRN = (id) =>
  loadAPI(`/edit-grn/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveGRNASN = (id) =>
  loadAPI(`/asn-data/?id=${id}`, {
    method: 'GET',
    secure: true,
  });

// export const retrieveGRN = (id) =>
//   loadAPI(`/grn-data/?id=${id}`, {
//     method: 'GET',
//     secure: true,
//   });

export const editFlow = (id, data) =>
  loadAPI(`/edit-flow/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const editAllotment = (id, data) => {
  return loadAPI(`/edit-allotment/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
    // headers: {
    //   'Content-Type': `multipart/form-data  boundary=${Math.random().toString().substr(2)}`,
    // },
  });
};

export const deleteGRN = (id) =>
  loadAPI(`/edit-grn/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const createRegGRN = (data) =>
  loadAPI('/create-regrn/', {
    method: 'POST',
    secure: true,
    data,
  });

export const editRegGRN = (id, data) =>
  loadAPI(`/edit-regrn/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const retrieveRegGRN = (id) =>
  loadAPI(`/edit-regrn/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveRegGRNs = () =>
  loadAPI('/regrns/', {
    method: 'GET',
    secure: true,
  });

export const deleteRegGRN = (id) =>
  loadAPI(`/edit-regrn/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const createDelivered = (req) =>
  loadAPI('/create-delivered/', {
    method: 'POST',
    data: req,
    secure: true,
  });
export const createReturnDelivered = (req) =>
  loadAPI('/create-returngatepass/', {
    method: 'POST',
    data: req,
    secure: true,
  });

export const createTransportVendor = (req) =>
  loadAPI(`/create-transportVendorlogs/`, {
    method: 'POST',
    data: req,
    secure: true,
  });

export const createReturnPODUpdate = (req) =>
  loadAPI('', {
    method: 'POST',
    data: req,
    secure: true,
  });

export const editTransportVendorLogs = (id, req) =>
  loadAPI(`/edit-transportVendorlogs/${id}/`, {
    method: 'PATCH',
    data: req,
    secure: true,
  });

export const editReturnPODUpdate = (id, req) =>
  loadAPI(`/return/${id}/transport-vendor/`, {
    method: 'POST',
    data: req,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const editAllotmentPODUpdate = (id, req) =>
  loadAPI(`/allotment/${id}/transport-vendor/`, {
    method: 'POST',
    data: req,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const createRelocationInward = (req) =>
  loadAPI('/create-relocationInward/', {
    method: 'POST',
    data: req,
    secure: true,
  });

export const createInward = (req) =>
  loadAPI('/create-inward/', {
    method: 'POST',
    data: req,
    secure: true,
  });

export const uploadDocuments = (req) =>
  loadAPI('/upload-docunments/', {
    method: 'POST',
    data: req,
    secure: true,
  });

export const uploadSCSDocuments = (req) =>
  loadAPI('/upload-scs-docunments/', {
    method: 'POST',
    data: req,
    secure: true,
  });

export const editDelivered = (id, req) =>
  loadAPI(`/e-delivered/${id}/`, {
    method: 'PATCH',
    data: req,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const retrieveDelivered = (id) =>
  loadAPI(`/edit-delivered/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const createOutwardDeliveredDocket = (req) =>
  loadAPI('/create-inward/', {
    method: 'POST',
    data: req,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });
export const editOutwardDeliveredDocket = (id, req) =>
  loadAPI(`/e-inward/${id}/`, {
    method: 'PATCH',
    data: req,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const retrieveOutwardDeliveredDocket = (id) =>
  loadAPI(`/edit-inward/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const deleteOutwardDeliveredDocket = (id) =>
  loadAPI(`/edit-inward/${id}/`, {
    method: 'Delete',
    secure: true,
  });

export const leadFileUpload = (req) =>
  loadAPI('/upload-scs/', {
    method: 'POST',
    data: req,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const tpFileUpload = (req) =>
  loadAPI('/upload-tp/', {
    method: 'POST',
    data: req,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const tpFileReUpload = (id, req) =>
  loadAPI(`/reupload-tp/${id}/`, {
    method: 'PATCH',
    data: req,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const retrieveTPs = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  selectedCustKeys,
}) =>
  loadAPI(
    `/tp-list/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&category=${selectedCustKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrieveTPsNew = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  selectedCustKeys,
  selectedSolution,
  selectedEmitter,
  selectedReceiver,
  clientName,
  flowType,
}) =>
  loadAPI(
    `/technical-proposals/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&category=${selectedCustKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}${flowType ? `&flow_type=${flowType}` : ''
    }${selectedSolution ? `&solution=${selectedSolution}` : ''}${selectedEmitter ? `&emitter=${selectedEmitter}` : ''
    }${selectedReceiver ? `&receiver=${selectedReceiver}` : ''} ${clientName ? `&client=${clientName}` : ''
    }`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const createReturn = (data) =>
  loadAPI('/create-return/', {
    method: 'POST',
    data,
    secure: 'true',
  });

export const editReturn = (
  id,
  {
    transaction_no,
    transaction_date,
    transaction_type,
    flow,
    kits,
    driver_name,
    driver_number,
    lr_number,
    vehicle_number,
    freight_charges,
    vehicle_type,
    transport_by,
    warehouse,
    receiver_client,
    remarks,
  }
) =>
  loadAPI(`/edit-return/${id}/`, {
    method: 'PATCH',
    data: {
      transaction_no,
      transaction_date,
      transaction_type,
      flow,
      kits,
      driver_name,
      driver_number,
      lr_number,
      vehicle_number,
      freight_charges,
      vehicle_type,
      transport_by,
      warehouse,
      receiver_client,
      remarks,
    },
    secure: 'true',
  });

export const retrieveReturn = (id) =>
  loadAPI(`/edit-return/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveTransportVendorLogs = (id) =>
  loadAPI(`/edit-transportVendorlogs/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveReturns = (id) =>
  loadAPI('/return-received/', {
    method: 'GET',
    secure: true,
    params: { id },
  });

export const deleteReturn = (id) =>
  loadAPI(`/edit-return/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const retrieveRFlows = () =>
  loadAPI('/r-flows/', {
    method: 'GET',
    secure: true,
  });

export const createReceived = (req) =>
  loadAPI('/create-received/', {
    method: 'POST',
    data: req,
    secure: true,
  });

export const editReceived = (id, req) =>
  loadAPI(`/e-received/${id}/`, {
    method: 'PATCH',
    data: req,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const retrieveReceived = (id) =>
  loadAPI(`/edit-received/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const deleteReceived = (id) =>
  loadAPI(`/edit-received/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const allDelivered = () =>
  loadAPI('/delivered/', {
    method: 'GET',
    secure: true,
  });

export const allInward = () =>
  loadAPI('/inward/', {
    method: 'GET',
    secure: true,
  });

export const allReceived = () => loadAPI('/received/', {});

export const retrieveGRNBars = (id) =>
  loadAPI(`/grn-barcodes/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveGRNBarCodes = (id) =>
  loadAPI(`/print-barcodes/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrieveAllotmentReport = ({ client, client_type, to, from }) =>
  loadAPI(`/allotment-report/`, {
    method: 'GET',
    secure: true,
    params: {
      client,
      client_type,
      to,
      from,
    },
  });

export const retrieveStockingReport = ({ to, from, cname }) =>
  loadAPI(`/floating-report/`, {
    method: 'GET',
    secure: true,
    params: {
      to,
      from,
      cname,
    },
  });

export const retrieveReturnReport = ({ cname, to, from }) =>
  loadAPI(`/return-report/`, {
    method: 'GET',
    secure: true,
    params: {
      cname,
      to,
      from,
    },
  });

export const retrieveAllotmentsDelivered = (id) =>
  loadAPI(`/allotments-items/?id=${id}`, {
    method: 'GET',
    secure: true,
    // params: { id },
  });

export const retrieveGRNDelivered = (id) =>
  loadAPI(`grn-items/?grn=${id}`, {
    method: 'GET',
    secure: true,
    // params: { id },
  });

export const retrieveOutwardDelivered = (id) =>
  loadAPI(`/outward-items/?id=${id}`, {
    method: 'GET',
    secure: true,
    // params: { id },
  });

export const retrieveReturnDelivered = (id) =>
  loadAPI(`/return-items/?id=${id}`, {
    method: 'GET',
    secure: true,
    // params: { id },
  });

export const retrieveRelocaionDelivered = (id) =>
  loadAPI(`/relocation-items/?id=${id}`, {
    method: 'GET',
    secure: true,
    // params: { id },
  });

export const retrieveDocketOutwardInward = (id) =>
  loadAPI('/outward-inward/', {
    method: 'GET',
    secure: true,
    params: { id },
  });

export const retrieveReturnDocket = (id) =>
  loadAPI(`/return-print/?id=${id}`, {
    method: 'GET',
    secure: false,
  });

export const retrieveReturnDocketCaleder = (id) =>
  loadAPI(`/returns-cal/?tno=${id}`, {
    method: 'GET',
    secure: true,
  });

export const createLead = (data) =>
  loadAPI('/create-leads/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createTP = (data) =>
  loadAPI('/create-tp/', {
    method: 'POST',
    secure: true,
    data,
  });

export const editTP = (id, data) =>
  loadAPI(`/edit-tp/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const retrieveTP = (id) =>
  loadAPI(`/edit-tp/${id}`, {
    method: 'GET',
    secure: true,
  });

// export const retrieveLeads = () =>
//   loadAPI('/leads/', {
//     method: 'GET',
//     secure: true,
//   });

export const retrieveLeads = ({
  companyId,
  viewType,
  page,
  pageSize,
  sortBy,
  tableSearch,
  selectedCustKeys,
  clientName,
  tableStatus,
  selectedSolution,
}) =>
  loadAPI(
    `/leads/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableSearch ? `&s=${tableSearch}` : ''
    }${clientName ? `&client=${clientName}` : ''}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedSolution ? `&solution=${selectedSolution}` : ''}${selectedCustKeys?.length > 0
      ? `&region=${selectedCustKeys.join(',')}`
      : ''
    }`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrieveCP = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  clientName,
  selectedSolution,
}) =>
  loadAPI(
    `/cp/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}${clientName ? `&client=${clientName}` : ''
    }${selectedSolution ? `&solution=${selectedSolution}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrievePFEP = () =>
  loadAPI('/pfep/', {
    method: 'GET',
    secure: true,
  });

export const retrieveSCSs = ({
  companyId,
  viewType,
  page,
  pageSize,
  sortBy,
  tableSearch,
  clientName,
}) =>
  loadAPI(
    `/scs/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}
    ${clientName ? `&client=${clientName}` : ''}
    ${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const editLead = (id, data) =>
  loadAPI(`/edit-leads/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const retrieveLead = (id) =>
  loadAPI(`/edit-leads/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const deleteLead = (id) =>
  loadAPI(`/edit-leads/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const postGrnBarcodes = (data, id) => {
  return loadAPI(`grn-barcode-save/?grn=${id}`, {
    method: 'POST',
    secure: true,
    data,
  });
};
export const postAllotBarcodes = (data, id) => {
  return loadAPI(`allotment-barcode-save/?allot=${id}`, {
    method: 'POST',
    secure: true,
    data,
  });
};

export const postReturnsBarcodes = (data, id) => {
  return loadAPI(`return-barcode-save/?return=${id}`, {
    method: 'POST',
    secure: true,
    data,
  });
};

export const postAltBarcodes = (data) => {
  return loadAPI('dispatch-allotment/', {
    method: 'POST',
    secure: true,
    data,
  });
};

export const patchAltBarcodes = (data, id) => {
  return loadAPI(`/dispatch-allotment-upd/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
};

export const postReturnBarcodes = (data) => {
  return loadAPI('dispatch-return/', {
    method: 'POST',
    secure: true,
    data,
  });
};
export const createPFEP = (data) => {
  return loadAPI('create-pfep/', {
    method: 'POST',
    secure: true,
    data,
  });
};

export const deletePFEP = (id) =>
  loadAPI(`/edit-pfep/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const editPFEP = (id, data) =>
  loadAPI(`/edit-pfep/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const createSCS = (data) => {
  return loadAPI('create-scs/', {
    method: 'POST',
    secure: true,
    data,
  });
};

export const retrieveSCS = (id) =>
  loadAPI(`/edit-scs/${id}/`, {
    method: 'GET',
    secure: true,
  });
export const retrieveSCSEditDetails = (id) =>
  loadAPI(`/scs-detail/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const deleteSCS = (id) =>
  loadAPI(`/edit-scs/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const editSCS = (id, data) =>
  loadAPI(`/edit-scs/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
export const SCSEditPatch = (id, data) =>
  loadAPI(`/scs-edit/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const createCP = (data) => {
  return loadAPI('create-cp/', {
    method: 'POST',
    secure: true,
    data,
  });
};

export const retrieveCPData = (id) =>
  loadAPI(`/edit-cp/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const deleteCP = (id) =>
  loadAPI(`/edit-cp/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const editCP = (id, data) =>
  loadAPI(`/edit-cp/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const retrieveTestInv = (data) => {
  return loadAPI('inv-items/', {
    method: 'GET',
    secure: true,
    data,
  });
};
export const retrieveWareInv = ({ cid }) => {
  return loadAPI(`/ware-inv-items/?warehouse=${cid}`, {
    method: 'GET',
    secure: true,
  });
};

export const createTestInv = (data) => {
  return loadAPI('create-inv/', {
    method: 'POST',
    secure: true,
    data,
  });
};

export const deleteTestInv = (id) =>
  loadAPI(`/edit-inv/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const retrieveSC2TestInvClientSide = (rp) => {
  return loadAPI(`sc-inv-items/?id=${rp.id}`, {
    method: 'GET',
    secure: true,
  });
};

export const retrieveSC2TestInv = (data) => {
  return loadAPI('sc-inv-items/', {
    method: 'GET',
    secure: true,
    data,
  });
};

export const createSC2TestInv = (data) => {
  return loadAPI('create-sc-inv/', {
    method: 'POST',
    secure: true,
    data,
  });
};

export const deleteSC2TestInv = (id) =>
  loadAPI(`/edit-sc-inv/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const retrieveRC2TestInv = (data) => {
  return loadAPI('rc-inv-items/', {
    method: 'GET',
    secure: true,
    data,
  });
};

export const createRC2TestInv = (data) => {
  return loadAPI('create-rc-inv/', {
    method: 'POST',
    secure: true,
    data,
  });
};

export const deleteRC2TestInv = (id) =>
  loadAPI(`/edit-rc-inv/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const createMRStatus = (data) => {
  return loadAPI(`mr-edit/${data.mr}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
};

export const retrieveDEPS = () =>
  loadAPI('/deps/', {
    method: 'GET',
    secure: true,
  });

export const retrieveDEP = (id) =>
  loadAPI(`/edit-deps/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const createDEPS = (src) =>
  loadAPI('/create-deps/', {
    method: 'POST',
    data: src,
    secure: true,
  });

export const editDEPS = (id, src) =>
  loadAPI(`/edit-deps/${id}/`, {
    method: 'PATCH',
    data: src,
    secure: true,
  });

export const deleteDEPS = (id) =>
  loadAPI(`/edit-deps/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const ticketFileUpload = (req) =>
  loadAPI('/upload-deps/', {
    method: 'POST',
    data: req,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const createExpense = (src) =>
  loadAPI('/create-expense/', {
    method: 'POST',
    data: src,
    secure: true,
    // headers: {
    //   'Content-Type': `multipart/form-data  boundary=${Math.random().toString().substr(2)}`,
    // },
  });

// export const retrieveExpenses = () =>
//   loadAPI('/expenses/', {
//     method: 'GET',
//     secure: true,
//   });

export const retrieveExpenses = ({
  companyId,
  viewType,
  page,
  pageSize,
  selectedCustKeys,
  tableStatus,
  sortBy,
  tableSearch,
}) => {
  return loadAPI(
    `/expenses/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&vendor=${selectedCustKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );
};

export const deleteExpense = (id) =>
  loadAPI(`/edit-expense/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const retrieveExpense = (id) =>
  loadAPI(`/edit-expense/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const editExpense = (id, src) =>
  loadAPI(`/edit-expense/${id}/`, {
    method: 'PATCH',
    data: src,
    secure: true,
  });

export const createExpenseHistory = (src) =>
  loadAPI(`/expense-history/`, {
    method: 'POST',
    data: src,
    secure: true,
  });

export const editExpenseTest = (id, src) =>
  loadAPI(`/ed-expense/${id}/`, {
    method: 'PATCH',
    data: src,
    secure: true,
    // headers: {
    //   'Content-Type': `multipart/form-data  boundary=${Math.random().toString().substr(2)}`,
    // },
  });

export const createEndOfLife = (src) =>
  loadAPI('/create-endOfLife/', {
    method: 'POST',
    data: src,
    secure: true,
  });

export const createAdjustment = (src) =>
  loadAPI('/create-warehouse-adjustments/', {
    method: 'POST',
    data: src,
    secure: true,
  });
export const createInvAdjustment = (src) =>
  loadAPI('/create-warehouse-inv-adjustments/', {
    method: 'POST',
    data: src,
    secure: true,
  });
export const createAssetDistributionCorrection = (src) =>
  loadAPI('/create-assetDistributionCorrection/', {
    method: 'POST',
    data: src,
    secure: true,
  });
export const retreiveAssetDistributionCorrection = (id) =>
  loadAPI(`/edit-assetDistributionCorrection/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const editAssetDistributionCorrection = (id, src) =>
  loadAPI(`/edit-assetDistributionCorrection/${id}/`, {
    method: 'PATCH',
    data: src,
    secure: true,
  });

export const retrieveAdjustments = () =>
  loadAPI('/adjustments/', {
    method: 'GET',
    secure: true,
  });
export const retrieveWarehouseAdjustments = () =>
  loadAPI('/warehouse-adjustments/', {
    method: 'GET',
    secure: true,
  });

export const retrieveAssetDistributionCorrection = () =>
  loadAPI('/assetDistributionCorrections/', {
    method: 'GET',
    secure: true,
  });

export const retrieveAdjustmentClients = () =>
  loadAPI('/clientadjust/', {
    method: 'GET',
    secure: true,
  });

export const uploadAdjustmentDocument = (src) =>
  loadAPI('/upload-audit/', {
    method: 'POST',
    data: src,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const createClientAdjustment = (src) =>
  loadAPI('/create-clientadjust/', {
    method: 'POST',
    data: src,
    secure: true,
  });

export const createGroup = (src) =>
  loadAPI('/create-group/', {
    method: 'POST',
    data: src,
    secure: true,
  });

export const retrieveGroups = () =>
  loadAPI('/group/', {
    method: 'GET',
    secure: true,
  });

export const deleteGroup = (id) =>
  loadAPI(`/edit-group/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const retrieveGroup = (id) =>
  loadAPI(`/edit-group/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const editGroup = (id, src) =>
  loadAPI(`/edit-group/${id}/`, {
    method: 'PATCH',
    data: src,
    secure: true,
  });

export const createSCAdjustment = (src) =>
  loadAPI('/create-sc-adjustments/', {
    method: 'POST',
    data: src,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const retrieveSCAdjustments = () =>
  loadAPI('/sc-adjustments/', {
    method: 'GET',
    secure: true,
  });

export const createRCAdjustment = (src) =>
  loadAPI('/create-rc-adjustments/', {
    method: 'POST',
    data: src,
    secure: true,
    headers: {
      'Content-Type': `multipart/form-data  boundary=${Math.random()
        .toString()
        .substr(2)}`,
    },
  });

export const retrieveRCAdjustments = () =>
  loadAPI('/rc-adjustments/', {
    method: 'GET',
    secure: true,
  });

export const createRelocation = (src) =>
  loadAPI('/create-relocation/', {
    method: 'POST',
    data: src,
    secure: true,
  });
export const uploadRelocation = (src) =>
  loadAPI('/upload-relocation/', {
    method: 'POST',
    data: src,
    secure: true,
  });

export const retrieveRelocations = () =>
  loadAPI('/relocations/', {
    method: 'GET',
    secure: true,
  });

export const retrieveEndOfLife = () =>
  loadAPI('/endOfLifes/', {
    method: 'GET',
    secure: true,
  });

export const uploadEndOfLife = (src) =>
  loadAPI('/upload-eol/', {
    method: 'POST',
    data: src,
    secure: true,
  });

export const uploadMasterWarehouse = (id, src) =>
  loadAPI(`/upload-pillarTags/?warehouse=${id}`, {
    method: 'POST',
    data: src,
    secure: true,
  });

export const uploadUserTags = (id, src) =>
  loadAPI(`/upload-tags/?emp=${id}`, {
    method: 'POST',
    data: src,
    secure: true,
  });

export const deleteRelocation = (id) =>
  loadAPI(`/edit-relocation/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const retrieveRelocation = (id) =>
  loadAPI(`/edit-relocation/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const editRelocation = (id, src) =>
  loadAPI(`/edit-relocation/${id}/`, {
    method: 'PATCH',
    data: src,
    secure: true,
  });

export const retrieveRelocationDocketData = (id) =>
  loadAPI(`/relocations-print/?id=${id}`, {
    method: 'GET',
    secure: false,
  });

export const forgotPassword = (src) =>
  loadAPI('/password-reset/', {
    method: 'POST',
    data: src,
    secure: false,
  });

export const confirmPassword = (src) =>
  loadAPI('/password-reset/confirm/', {
    method: 'POST',
    data: src,
    secure: false,
  });

export const createCompanyInt = (data) =>
  loadAPI('/create-company-int/', {
    method: 'POST',
    data,
  });

export const retrieveCompanyList = ({ page, pageSize }) =>
  loadAPI(`/company-list/?page=${page}&page_size=${pageSize}`, {
    method: 'GET',
    secure: true,
  });

export const createUser = (data) =>
  loadAPI('/create-employee/', {
    method: 'POST',
    data,
  });

export const retrieveUser = (id) =>
  loadAPI(`/emp-profile/${id}/`, {
    method: 'GET',
  });

export const editUser = (id, data) =>
  loadAPI(`/emp-profile/${id}/`, {
    method: 'PATCH',
    data,
  });

export const retrieveEmployeeList = ({
  companyId,
  page,
  pageSize,
  viewType,
  tableSearch,
}) =>
  loadAPI(
    `/employees/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}${tableSearch && `&s=${tableSearch}`
    }`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrieveVendorss = ({
  companyId,
  viewType,
  page,
  pageSize,
  sortBy,
  tableSearch,
  tableStatus,
  selectedState,
  selectedType,
}) =>
  loadAPI(
    `/vendors/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedType ? `&vendor_type=${selectedType}` : ''}${selectedState ? `&state=${selectedState}` : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const retrievePriceList = ({ companyId, page, pageSize, viewType }) =>
  loadAPI(
    `/priceLists/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );

export const deleteEmployee = (id) =>
  loadAPI(`/emp-profile/${id}/`, {
    method: 'DELETE',
    secure: true,
  });

export const createPriceList = (data) =>
  loadAPI('/create-priceList/', {
    method: 'POST',
    data,
  });
export const editPriceList = (id, data) =>
  loadAPI(`/edit-priceList/${id}/`, {
    method: 'PATCH',
    data,
  });

export const retrievePriceFormData = (id) =>
  loadAPI(`/edit-priceList/${id}/`, {
    method: 'GET',
  });

export const createPurchseOrder = (data) =>
  loadAPI('/create-po/', {
    method: 'POST',
    secure: true,
    data,
  });
export const createAltInvoice = (data) =>
  loadAPI('/sales-invoices/', {
    method: 'POST',
    secure: true,
    data,
  });
export const getNewDashboardTableData = (cname, filterDate) =>
  // const { data: graphTableData, loading: tableGraphLoading } = await loadAPI(
  // `asset-distribution/?cname=${cstmrId}&&date=${filteredDate}`
  loadAPI(`asset-distribution/?cname=${cname}&&date=${filterDate}`, {
    method: 'GET',
    secure: true,
    // data,
  });
export const editPurchaseOrder = (id, data) =>
  loadAPI(`/edit-po/${id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });

export const retrievePurchaseOrder = (id) =>
  loadAPI(`/edit-po/${id}/`, {
    method: 'GET',
    secure: true,
  });

export const retrievePurchaseOrders = ({
  companyId,
  viewType,
  page,
  pageSize,
  tableSearch,
  tableStatus,
  sortBy,
  selectedWareKeys,
  selectedCustKeys,
}) => {
  return loadAPI(
    `/purchaseorders/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&vendor=${selectedCustKeys.join(',')}`
      : ''
    }${selectedWareKeys?.length > 0
      ? `&warehouse=${selectedWareKeys.join(',')}`
      : ''
    }${tableSearch ? `&s=${tableSearch}` : ''}`,
    { method: 'GET', secure: true }
  );
};
export const retrievePurchaseOrdersVendor = ({
  vendorId,
  companyId,
  viewType,
  page,
  pageSize,
}) => {
  return loadAPI(
    `/purchaseorders-vendors/?vendor=${vendorId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
      secure: true,
    }
  );
};
export const retrieveDepartmentTable = () => {
  return loadAPI(`/departments/`, {
    method: 'GET',
    secure: true,
  });
};

export const printPurchaseOrders = (id) => {
  return loadAPI(`/print-purchaseorders/`, {
    method: 'GET',
    secure: true,
    params: { id },
  });
};

export const getASNTableDataVendor = ({
  companyId,
  viewType,
  page,
  pageSize,
}) => {
  return loadAPI(
    `/asn-list-vendor/?company=${companyId}&view=${viewType}&page=${page}&page_size=${pageSize}`,
    { method: 'GET', secure: true }
  );
};

export const getASNTableData = ({
  companyId,
  viewType,
  page,
  pageSize,
  sortBy,
  selectedWareKeys,
  selectedCustKeys,
  tableStatus,
  tableSearch,
  selectedType,
}) =>
  loadAPI(
    `/asn-list/?company=${companyId}${sortBy ? `&sort_by=${sortBy}` : ''
    }&view=${viewType}&page=${page}&page_size=${pageSize}${tableStatus ? `&status=${tableStatus}` : ''
    }${selectedCustKeys?.length > 0
      ? `&origin=${selectedCustKeys.join(',')}`
      : ''
    }${selectedWareKeys?.length > 0
      ? `&destination=${selectedWareKeys.join(',')}`
      : ''
    }${selectedType ? `&transaction_type=${selectedType}` : ''}${tableSearch ? `&s=${tableSearch}` : ''
    }`,
    {
      method: 'GET',
      secure: true,
    }
  );

// export const retrievePurchaseOrder = (id) =>
//   loadAPI(`/edit-po/${id}/`, {
//     method: 'GET',
//     secure: true,
//   });
export const createExecuteAudit = (data) =>
  loadAPI('/audit-execute/', {
    method: 'POST',
    secure: true,
    data,
  });

export const createAudit = (data) =>
  loadAPI('/create-audit-initiation/', {
    method: 'POST',
    secure: true,
    data,
  });
export const retrieveCreateAudit = (id) =>
  loadAPI(`edit-audit-initiation/${id}`, {
    method: 'GET',
    secure: true,
  });
export const EditCreateAudit = (id, data) =>
  loadAPI(`edit-audit-initiation/${id}/`, {
    method: 'PATCH',
    data,
  });
export const retrieveAudits = () =>
  // { viewType, page, pageSize }
  loadAPI(`/audits/`, {
    method: 'GET',
    secure: true,
  });
// loadAPI(`/audits/?view=${viewType}&page=${page}&page_size=${pageSize}`, {
//   method: 'GET',
//   secure: true,
// });

export const createTrip = (values) => {
  return loadAPI('/create-trip/', {
    method: 'POST',
    secure: true,
    data: { ...values },
  });
};

export const retrieveTrips = () => {
  return loadAPI('/trips/', { method: 'GET', secure: true, params: {} });
};

export const patchTripStatus = (data) => {
  return loadAPI(`/edit-trip/${data.id}/`, {
    method: 'PATCH',
    secure: true,
    data,
  });
};

export const endTrip = (tripId, objId) => {
  return loadAPI('/end-trip/', {
    method: 'POST',
    secure: true,
    params: { tripId, objId },
  });
};

export const getTrackDataForGoogleMaps = () => {
  return loadAPI(`/track/`, { method: 'GET', secure: true, params: {} });
};

export const getCountry = async () => {
  try {
    const { data } = await Axios({
      method: 'get',
      url: 'https://api.countrystatecity.in/v1/countries',
      headers: { 'X-CSCAPI-KEY': API_KEY },
    });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getStateFromCountry = async (cntryName) => {
  try {
    const { data } = await Axios({
      method: 'get',
      url: `https://api.countrystatecity.in/v1/countries/${cntryName}/states`,
      headers: { 'X-CSCAPI-KEY': API_KEY },
    });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const computeCustomTPSummary = (payload) =>
  loadAPI('/custom-tp-summary/', {
    method: 'POST',
    data: payload,
    secure: true,
  });

export const approveTpSummary = (id) =>
  loadAPI(`/approved-tp-summary/${id}/`, {
    method: 'POST',
    data: {},
    secure: true,
  });
