import { USER_AUTHENTICATED, SIGN_OUT_USER } from './index';

export const userAuthenticated = (currentUser) => {
  console.log({ currentUser });
  return {
    type: USER_AUTHENTICATED,
    payload: currentUser,
  };
};

export const signOutUser = () => ({
  type: SIGN_OUT_USER,
});

export const updateUserMetaData = (updatedUserData) => {
  return {
    type: 'UPDATED_DATA',
    payload: updatedUserData,
  };
};
