import React from 'react';
import { Router } from '@reach/router';
import ScreenWrapper from 'components/ScreenWrapper';
import Redirect from 'components/Redirect';
import { NotFound404Screen } from 'screens/404.screen';
import useModuleConfig from 'hooks/useModuleConfig';

export const PrivateRoutes = ({
  routes = [],
  extraRoutes = [],
  user,
  outerRoutes = [],
  companyProfile,
}) => {
  const { moduleConfig } = useModuleConfig({ formKey: '' });

  // Helper function to render routes
  const renderRoutes = (routesList) => {
    return routesList.map((Route, index) => {
      if (
        !Route.key ||
        moduleConfig?.[Route.key] ||
        companyProfile?.[Route.key]
      ) {
        return (
          <Route.Component
            path={Route.path}
            key={`${Route.path}-${index}`}
            {...Route.props}
          />
        );
      }
      return null;
    });
  };

  // Render submenu routes
  const renderSubMenu = (subMenu) => {
    return subMenu?.map((ChildRoute, index) => {
      if (
        !ChildRoute.key ||
        moduleConfig?.[ChildRoute.key] ||
        companyProfile?.[ChildRoute.key]
      ) {
        return (
          <ChildRoute.Component
            path={ChildRoute.path}
            key={`${ChildRoute.path}-${index}`}
            {...ChildRoute.props}
          />
        );
      }
      return null;
    });
  };

  return (
    <Router>
      <Redirect path="/" user={user} />
      {outerRoutes.map((Route, index) => (
        <Route.Component
          path={`/${user?.type}${Route.path}`}
          key={`${Route.path}-${index}`}
          {...Route.props}
        />
      ))}
      <ScreenWrapper
        moduleConfig={moduleConfig}
        path={`/${user.type}/`}
        routes={routes}
        companyProfile={companyProfile}
      >
        {renderRoutes(routes)}
        {routes.map((Route) => renderSubMenu(Route.subMenu))}
        {renderRoutes(extraRoutes)}
        <NotFound404Screen default />
      </ScreenWrapper>
    </Router>
  );
};
