import React, { Suspense, useState } from 'react';
import {
  Input,
  Button,
  Form,
  Avatar,
  Typography,
  Dropdown,
  notification,
  Spin,
  Row,
  Col,
} from 'antd';
// import logo from 'common/assets/header_logo.png';
import { loadAPI } from 'common/helpers/api';
import logo from 'common/assets/header_logo.png';
import { navigate } from '@reach/router';
import { Loading } from 'components/Loading';
import { useDispatch } from 'react-redux';
import { signOutUser } from 'common/actions/signIn';
import { LockOutlined } from '@ant-design/icons';
const { Title } = Typography;

const OtpVerification = ({ user, loading }) => {
  const [otp, setOtp] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    console.log(e.target.value, 'ee');
    setOtp(e.target.value);
  };
  const resendOtp = async () => {
    const data = await loadAPI(`/resend-otp/`, {
      method: 'POST',
    });
    console.log(data, 'message');
    notification.success({
      message: data?.data,
      // description: `Sorry! No More can be added`,
    });
  };

  const handleSubmit = async () => {
    const userData = await loadAPI(`verify-onboarding/`, {
      method: 'POST',
      data: { otp: Number(otp) },
    });
    console.log(userData,"OTP Verification");

    if (userData?.data === 'Otp Verified Successfully') {
      notification.success({
        message: userData?.data,
        // description: `Sorry! No More can be added`,
      });
      navigate('/');
    } else {
      notification.error({
        message: userData?.data,
        description: `You have entered wrong OTP`,
      });
    }
  };

  return (
    <>
      <Row>
        <Col span={11}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              style={{ height: '100%', marginTop: '0.8rem', marginLeft: 15, width: '9rem' }}
              alt="Yantraksh"
              src={logo}
            />
          </div>
        </Col>
        <Col span={12} align="right">
          <Button
            className="my-2"
            onClick={async () => {
              dispatch(signOutUser());
              await navigate('/');
            }}>
            Log out
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          display: 'flex',
          alignItems: 'center',
          background: '#f5f5f5',
          height: '30vh',
        }}>
        <Col span={24} align="center">
          <p>
            To ensure the security of your account, we require OTP (One-Time Password) verification.
          </p>
          <p style={{ fontSize: '16px', fontWeight: 'bolder' }}>
            Please check your registered email address for the OTP.
          </p>
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          // alignItems: 'center',
          height: '60vh',
          background: '#f5f5f5',
        }}>
        <div>
          <div
            style={{
              padding: 24,
              borderRadius: 8,
              background: '#ffffff',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}>
            <Title level={3} style={{ textAlign: 'center' }}>
              Enter OTP
            </Title>
            <Form onFinish={handleSubmit}>
              <Form.Item
                // name="otp"
                rules={[
                  { required: true, message: 'Please enter the OTP' },
                  { len: 6, message: 'OTP must be 6 digits' },
                ]}>
                <Input
                  prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                  placeholder="OTP"
                  size="large"
                  value={otp}
                  onChange={handleChange}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block size="large">
                  Verify
                </Button>
              </Form.Item>
              <Row>
                <Col span={12}>
                  <p>Didn't receive OTP</p>
                </Col>
                <Col align="right" span={11}>
                  <Button onClick={() => resendOtp()}>Resend</Button>
                </Col>
              </Row>
              {/* <Form.Item></Form.Item> */}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerification;
